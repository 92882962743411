// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!./xkUI/pages/Guiding/src/css/index.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#app{height:100%;background-size:100% 100%;background-repeat:no-repeat;background-image:var(--layout-bc-img)}.loading{background-color:#ebf2fa;background:-webkit-linear-gradient(350deg,hsla(0,0%,100%,0) 40%,hsla(0,0%,100%,.5) 50%,hsla(0,0%,100%,0) 60%) #ebf2fa;background:-moz-linear-gradient(350deg,hsla(0,0%,100%,0) 40%,hsla(0,0%,100%,.5) 50%,hsla(0,0%,100%,0) 60%) #ebf2fa;background:linear-gradient(100deg,hsla(0,0%,100%,0) 40%,hsla(0,0%,100%,.5) 50%,hsla(0,0%,100%,0) 60%) #ebf2fa;background-size:200% 100%;background-position-x:180%;-webkit-animation:loading 1s ease-in-out infinite;-moz-animation:loading 1s ease-in-out infinite;animation:loading 1s ease-in-out infinite}@-webkit-keyframes loading{to{background-position-x:-20%}}@-moz-keyframes loading{to{background-position-x:-20%}}@keyframes loading{to{background-position-x:-20%}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
